import { cn } from '../../utils/cn';

export function DuplicateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={cn('w-3.5 h-3.5 fill-current', props.className)}
      viewBox='0 0 14 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M6.68421 10.1818H2.26316C1.56554 10.1818 1 9.612 1 8.90909V1.27273C1 0.569819 1.56554 0 2.26316 0H9.20574C9.5572 0 9.8421 0.28491 9.8421 0.636364C9.8421 0.987818 9.55719 1.27273 9.20574 1.27273H2.26316V8.90909H6.68421V8.03818C6.68421 7.87304 6.87304 7.77906 7.00479 7.87862L8.8938 9.30611C9.05264 9.42614 9.05264 9.66477 8.8938 9.7848L7.00479 11.2123C6.87304 11.3119 6.68421 11.2179 6.68421 11.0527V10.1818Z' />
      <path d='M11.7368 12.7273V3.81817H4.78946V7.00477C4.78946 7.35358 4.5067 7.63635 4.15789 7.63635C3.80907 7.63635 3.52631 7.35358 3.52631 7.00477V3.81817C3.52631 3.11526 4.09184 2.54544 4.78946 2.54544H11.7368C12.4345 2.54544 13 3.11526 13 3.81817V12.7273C13 13.4302 12.4345 14 11.7368 14H4.78946C4.09184 14 3.52631 13.4302 3.52631 12.7273V12.0861C3.52631 11.7373 3.80907 11.4545 4.15789 11.4545C4.5067 11.4545 4.78946 11.7373 4.78946 12.0861V12.7273H11.7368Z' />
    </svg>
  );
}
